import React from "react";
import { useForm } from "react-hook-form";
import { send } from "@emailjs/browser";
import "./contact.css";
import toast from "react-hot-toast";

const Contact = () => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		send(
			"youssef_website", // Replace with your EmailJS service ID
			"template_1w706zl", // Replace with your EmailJS template ID
			data,
			"Tz2PkCylwrZfgH9fZ" // Replace with your EmailJS user ID
		)
			.then((result) => {
				toast.success("Message sent successfully");
				reset();
				console.log("Message sent successfully", result.text);
			})
			.catch((error) => {
				console.log("Failed to send message", error.text);
			});
	};

	return (
		<section className="container contact section" id="contact">
			<h2 className="section__title">Get In Touch</h2>

			<div className="contact__container grid">
				<div className="contact__info">
					<h3 className="contact__title">Let's Talk About Everything!</h3>
					<p className="contact__details">
						Don't like forms? Send me a message. 👋
					</p>
				</div>

				<form onSubmit={handleSubmit(onSubmit)} className="contact__form">
					<div className="contact__form-group">
						<div className="contact__form-div">
							<input
								type="text"
								className="contact__form-input"
								placeholder=" name"
								{...register("name", { required: "Name is required" })}
							/>
							{errors.name && <span>{errors.name.message}</span>}
						</div>

						<div className="contact__form-div">
							<input
								type="email"
								className="contact__form-input"
								placeholder=" email"
								{...register("email", { required: "Email is required" })}
							/>
							{errors.email && <span>{errors.email.message}</span>}
						</div>
					</div>
					<div className="contact__form-div">
						<input
							type="text"
							className="contact__form-input"
							placeholder=" phone number"
							{...register("phone_number")}
						/>
					</div>
					<div className="contact__form-div">
						<input
							type="text"
							className="contact__form-input"
							placeholder=" subject"
							{...register("subject")}
						/>
					</div>

					<div className="contact__form-div contact__form-area">
						<textarea
							className="contact__form-input"
							placeholder="Write your message"
							{...register("message", { required: "Message is required" })}
						/>
						{errors.message && <span>{errors.message.message}</span>}
					</div>

					<div className="contact-btn">
						<button className="btn" type="submit">
							Send Message
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Contact;
