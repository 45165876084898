import React from "react";
import "./about.css";
import AboutBox from "./AboutBox";
import { useSelector } from "react-redux";
import Technologies from "./Technologies";
const About = () => {
	const authorImage2 = useSelector((res) => res.data.author?.["image2"]?.url);
	const cvLink = useSelector((res) => res.data.author?.cv?.url);
	let skills = useSelector((res) => res.data?.skills);

	// console.log(skills);
	let aboutDescription = useSelector((res) => res.data.about);
	aboutDescription =
		aboutDescription &&
		aboutDescription.map((ele) => {
			if (ele.title === "about_description") {
				return ele.aboutDescription;
			}
		});

	return (
		<section className="about container section" id="about">
			<h2 className="section__title">About Me</h2>
			<div className="about__container grid">
				<img src={authorImage2} className="about__img" alt="author image" />
				<div className="about__data grid">
					<div className="about__info">
						<p className="about_description">{aboutDescription}</p>
						<a
							href={cvLink}
							target="_blank"
							download="Front-End Developer.pdf"
							className="btn">
							Download Cv
						</a>
					</div>
					{/* <div className="about__skills grid">
						{skills &&
							skills.map((ele, index) => {
								let { title, percentage, color } = ele;

								return (
									<div className="skills__data" key={index}>
										<div className="skills__title">
											<h3 className="skills__name">{title}</h3>
											<span className="skills__number">{percentage}%</span>
										</div>
										<div className="skills__bar">
											<span
												className="skills__percentage"
												style={{
													width: percentage + "%",
													backgroundColor: color,
												}}></span>
										</div>
									</div>
								);
							})}
					</div> */}
				</div>
			</div>
			<Technologies />
			<AboutBox />
		</section>
	);
};
export default About;
